/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageFunctionalities/pageMarcadorPredictivo/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageCasosdeUso/components/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionRight from "@components/sharedComponents/accordionRight/accordionRight"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
 
import Experience from "@components/pageFunctionalities/pageMarcadorPredictivo/experience/experience"
import { TitleUnderlineRightOne, TitleUnderlineRightTwo } from "@components/pageFunctionalities/pageMarcadorPredictivo/titleUnderline/titleUnderlineAccordionRight"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import BannerContact from "@components/sharedComponents/bannerContact/bannerContact"
import Operations from "@components/pageHome/operations/operations"
import { TitleUnderlineOperationsMarcadorPredictivoOne, TitleUnderlineOperationsMarcadorPredictivoTwo } from "@components/pageHome/operations/titleUnderline"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import { TitleUnderlineTestimonyOne, TitleUnderlineTestimonyTwo } from "@components/pageFunctionalities/pageMarcadorProgresivo/titleUnderline/titleUnderlineTestimony"
import cobros from "@components/sharedComponents/testimonies/images/iconos/cobros.svg"
import ecommerce from "@components/sharedComponents/testimonies/images/iconos/ecommerce.svg"

const TemplatePageMarcadorPredictivo = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionRight = <p>
    Beex convierte cada contacto en una oportunidad de venta o cobro gracias a sus <TitleUnderlineRightOne underline="funciones avanzadas" />
  </p>

  const titleOperations = <p className="container-operations-title">
    Nuestro Marcador Predictivo <TitleUnderlineOperationsMarcadorPredictivoOne underline="no se limita" /> a ninguna operación de tu Contact Center
  </p>

  const titleTestimony = <p>
    Nuestro marcador <TitleUnderlineTestimonyOne underline="optimiza la" /> <TitleUnderlineTestimonyTwo underline="gestión" /> telefónica de los principales sectores del mercado

  </p>

  const iconsSilder = i => {
    switch (i) {
      case 0:
        return ecommerce
      case 1:
        return cobros
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Televentas"
      case 1:
        return "Cobros"
      default:
        return "Conoce otros casos de éxito"
    }
  }

  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        
        <Header
          path="/productos/"
          location={location}
          windowsWidth={windowsWidth}
        />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/m3URcMeQCWs?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} imageViewResponsive={true} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="Únete a las empresas que automatizaron la gestión de sus llamadas salientes con Beex" />
          <AccordionRight data={data.accordionRight} title={titleAccordionRight} description={""} />
          <BannerCTA
            title="Olvídate de los altos tiempos de espera entre llamadas"
            text="Conoce en tiempo real como trabaja nuestro marcador predictivo y cómo puede ayudarte."
            link="/hablemos/"
            textButton="Agendar reunión"
          />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Aumenta la tasa de ocupación de tus agentes"
            text="Nuestros expertos te ayudarán a que tus operaciones telefónicas sean ágiles y de alta calidad."
            link="/hablemos/"
            textButton="Agendar reunión"
          />
          <Operations data={data.modules} location={location} title={titleOperations} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <BannerContact data={data.contacts} location={location} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageMarcadorPredictivo
